import React, { useEffect, useState } from "react";
import "./styles.scss";
import { IonCol, IonGrid, IonHeader, IonImg, IonLabel, IonRow, IonSpinner, IonTitle } from "@ionic/react";
import Layout from "../../../components/Layout";
import { useTranslation } from "react-i18next";
import starIconWhite from "../../../images/icons/star-sharp-white.svg"
import { BillingServices } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { ReduxSelectors } from "../../../redux/shared/types";
import { TransactionStarsTable } from "../../../shared/types";
import CurrentBalanceBox from "../../../components/CurrentBalance";
import { setErrorToast } from "../../../redux/actions/toastActions";
import { updateStarsBalance } from "../../../shared/helpers";
import { setTotalStarBalance } from "../../../redux/actions/billingRewardActions";

const StarsStatusTable: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id, isAnonymous, jwt } = useSelector(({ profile }: ReduxSelectors) => profile)
  const [starsTable, setStarsTable] = useState<TransactionStarsTable[]>([])
  const [loading, setLoading] = useState<Boolean>(false)
  // eslint-disable-next-line
  // const [totalStars, setTotalStars] = useState<number>(0)

  // const calculateTotalStars = (transaction: TransactionStarsTable[]) => {
  //   let amount = 0
  //   transaction.map((item) => (
  //     amount += item[0].amount
  //     // return amount
  //   ))
  //   setTotalStars(amount)
  // }

  useEffect(() => {
    const page = 1
    const pageSize = 100
    if (jwt && !isAnonymous) {
      setLoading(true)
      BillingServices.billingStarsStatusTable(id, page, pageSize).then(async ({ data: { result, status } }) => {

        console.log("stars result", result)
        if (status === 'nok') {
          setErrorToast("Something went wrong. Please try again later.")
          setStarsTable([])
          setLoading(false)
        }
        if (status === 'ok') {
          // calculateTotalStars(result.transactions)
          const starsBalance = await updateStarsBalance(id)
          dispatch(setTotalStarBalance(starsBalance))
          setStarsTable(result.transactions)
          setLoading(false)
        }
      })
    }
  }, [id, isAnonymous, jwt, dispatch])

  console.log("starsTable", starsTable, "loading:", loading)

  useEffect(() => {
    if (starsTable) {
      starsTable?.map((item, index) => {
        console.log("item", item)
        return item;
      })
    }
  }, [starsTable])

  return (
    <Layout className="stars-status-layout">
      <IonHeader>
        <IonTitle>{t('billing.starsStatus.header')}</IonTitle>
      </IonHeader>

      <div className="status-table-balance-box">
        <CurrentBalanceBox
          starsLabel={t('billing.starsStatus.currentBal')}
        />
      </div>

      <div className="stars-status-table-container">


        <IonGrid fixed={true} className="table-grid">
          <IonRow >
            <IonHeader className="table-header">
              <IonTitle>{t('billing.starsStatus.starsTransaction')}</IonTitle>
            </IonHeader>
          </IonRow>

          <IonRow className="stars-status-table">
            <IonCol size="auto" className="star-col-1" >{t('billing.starsStatus.date')}</IonCol>
            <IonCol size="auto" className="star-col-2" >{t('billing.starsStatus.type')}</IonCol>
            <IonCol size="auto" className="star-col-3" >
              <IonImg src={starIconWhite} />
              <IonLabel>{t('billing.starsStatus.stars')}</IonLabel>
            </IonCol>
          </IonRow>
          <hr className="horizontal-row" />

          {
            starsTable ? starsTable?.map((item, index) => {
              const splittedStars = item.amount.toString().match(/^([+-]?)(\d+)$/)?.slice(1)
              const splittedDate = item.date.substring(0, 10)

              return (
                <div key={index}>
                  <IonRow className="stars-status-table">
                    <IonCol size="auto" className="star-col-1" >{splittedDate}</IonCol>
                    <IonCol size="auto" className="star-col-2" >{item.type}</IonCol>
                    <IonCol size="auto" className="star-col-3" >
                      <IonLabel style={{ marginRight: 10 }}>{`${splittedStars?.[0] === "" ? "+" : splittedStars?.[0]}${splittedStars?.[1]}`}</IonLabel>
                    </IonCol>
                  </IonRow>
                  <hr className="horizontal-row" />
                </div>
              )
            })
              :
              loading && <IonRow className="stars-table-spinner">
                <IonSpinner />
              </IonRow>
          }
        </IonGrid>
      </div>
    </Layout>
  );
};

export default StarsStatusTable;
