import React, { FC, useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'videojs-ima'; // Ensure this is correctly imported
import './styles.scss';

type Props = {
  className: string;
};

const adTagUrl =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=480x720|1024x768|300x250|400x300|336x280&iu=/24458126/O2A_Preroll_web&env=vp&impl=s&gdfp_req=1&output=vast&unviewed_position_start=1&url=https://www.12all.tv&description_url=https://www.12all.tv&correlator=[timestamp]';

const GoogleAdStream: FC<Props> = ({ className }) => {
  const videoElement = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);
  const wrapperDivRef = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line
  const [adTagInput, setAdTagInput] = useState(adTagUrl || '');

  useEffect(() => {
    const initPlayer = () => {
      if (videoElement.current) {
        playerRef.current = videojs(videoElement.current, {
          controls: true,
          preload: 'auto',
          width: 640,
          height: 360,
          sources: [
            {
              src: 'https://redirector.gvt1.com/videoplayback/id/115600533c381734/itag/106/source/dclk_video_ads/requiressl/yes/acao/yes/mime/video%2Fmp4/ctier/L/ip/0.0.0.0/ipbits/0/expire/1717071125/sparams/ip,ipbits,expire,id,itag,source,requiressl,acao,mime,ctier/signature/3A325E1040BCB6C2A599FB2119B7401651138059.04AC1D9F88C9DFA1F102C14A8BB62E7339C4D895/key/ck2/file/file.mp4',
              type: 'video/mp4',
            },
          ],
        });

        // Ensure the IMA plugin is registered before using it
        if (playerRef.current.ima) {
          const options = {
            id: 'content_video',
            adsManagerLoadedCallback: adsManagerLoadedCallback,
          };

          playerRef.current.ima(options);

          const startEvent = 'click';

          const wrapperDiv = wrapperDivRef.current;
          if (wrapperDiv) {
            wrapperDiv.addEventListener(startEvent, () => {
              initAdDisplayContainer();
              wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
            });
          }
        } else {
          console.error('IMA plugin is not available.');
        }
      }
    };

    const initAdDisplayContainer = () => {
      if (playerRef.current) {
        playerRef.current.ima.initializeAdDisplayContainer();
        playerRef.current.ima.setContentWithAdTag(null, adTagInput, false);
        playerRef.current.ima.requestAds();
      }
    };

    const adsManagerLoadedCallback = () => {
      const events = [
        google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        google.ima.AdEvent.Type.CLICK,
        google.ima.AdEvent.Type.COMPLETE,
        google.ima.AdEvent.Type.FIRST_QUARTILE,
        google.ima.AdEvent.Type.LOADED,
        google.ima.AdEvent.Type.MIDPOINT,
        google.ima.AdEvent.Type.PAUSED,
        google.ima.AdEvent.Type.RESUMED,
        google.ima.AdEvent.Type.STARTED,
        google.ima.AdEvent.Type.THIRD_QUARTILE,
        google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
        google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      ];

      events.forEach((event) => {
        playerRef.current!.ima.addEventListener(event, onAdEvent);
      });

      playerRef.current!.on('adslog', onAdLog);
    };

    const onAdLog = (data: any) => {
      console.log('Ad log: ', data.data.AdError);
    };

    const onAdEvent = (event: any) => {
      console.log('Ad event: ', event.type);
    };

    initPlayer();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [adTagInput]);

  return (
    <div className={className}>
      <div ref={wrapperDivRef}>
        <video ref={videoElement} id="content_video" className="video-js vjs-default-skin" autoPlay></video>
      </div>
    </div>
  );
};

export default GoogleAdStream;
